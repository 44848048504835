<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center">
            <b-card class='w-100 card-designs'>
              <b-row>
                <b-col cols="12">
                  <b-row class="d-flex justify-content-start align-items-center h-md-25vh">
                    <b-col cols="4">
                      <b-avatar 
                        size="48"
                        class="avatar-border-2 box-shadow-1 d-block d-md-none"
                        variant="primary"
                        :src="userAvatar" 
                      />
                      <b-img 
                        fluid
                        class="d-none d-md-inline-block"
                        :src="userAvatar" 
                      />
                    </b-col>
                    <b-col cols="auto text-light">
                      <p class="mb-1 fs-16">Hi, {{ userData.first_name }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-button variant="primary" size="sm" class="text-light mr-1">
                    Overview
                  </b-button>
                  <b-button variant="primary" size="sm" class="text-light mr-1" :to="{name: 'user-profile', query: {tab: 'subscription'}}">
                    Subscriptions
                  </b-button>
                  <b-button variant="primary" size="sm" class="text-light" :to="{name: 'user-profile', query: {tab: 'profile'}}">
                    Profile
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="8">
            <b-row>
              <b-col cols="12" md="6">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2><strong>{{ usageData.word_used }}<span class="text-muted">/{{ usageData.word_balance }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <b-card-title class="text-uppercase">
                          {{ $t('Words generated') }}
                        </b-card-title>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img 
                        fluid
                        class="pr-1"
                        :src="require('@/assets/images/app-icons/ai/chat-48.png')" 
                      />
                    </div>
                  </div>
                  <b-progress class="mt-1" :value="usageData.word_used" :max="usageData.word_balance" show-progress animated variant="danger"></b-progress>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2><strong>{{ usageData.image_used }}<span class="text-muted">/{{ usageData.image_balance }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <b-card-title class="text-uppercase">
                          {{ $t('Images generated') }}
                        </b-card-title>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img 
                        fluid
                        class="pr-1"
                        :src="require('@/assets/images/app-icons/ai/image-48.png')" 
                      />
                    </div>
                  </div>
                  <b-progress class="mt-1" :value="usageData.image_used" :max="usageData.image_balance" show-progress animated variant="danger"></b-progress>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2><strong>{{ usageData.t2s_used }}<span class="text-muted">/{{ usageData.t2s_balance }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <b-card-title class="text-uppercase">
                          {{ $t('Voice generated') }}
                        </b-card-title>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img 
                        fluid
                        class="pr-1"
                        :src="require('@/assets/images/app-icons/ai/speech-48.png')" 
                      />
                    </div>
                  </div>
                  <b-progress class="mt-1" :value="usageData.t2s_used" :max="usageData.t2s_balance" show-progress animated variant="danger"></b-progress>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2><strong>{{ usageData.s2t_used }}<span class="text-muted">/{{ usageData.s2t_balance }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <b-card-title class="text-uppercase">
                          {{ $t('Voice to text generated') }}
                        </b-card-title>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img 
                        fluid
                        class="pr-1"
                        :src="require('@/assets/images/app-icons/ai/voice-48.png')" 
                      />
                    </div>
                  </div>
                  <b-progress class="mt-1" :value="usageData.s2t_used" :max="usageData.s2t_balance" show-progress animated variant="danger"></b-progress>
                </b-card>
              </b-col>
              <b-col cols="12" md="6" class="d-none">
                <b-card class='mb-1'>
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <b-avatar 
                        size="36"
                        class="avatar-border-2 box-shadow-1"
                        variant="primary"
                        :src="require('@/assets/images/app-icons/ai/order-48.png')" 
                      />
                    </div>
                    <div>
                      <h4><strong>{{ usageData.orders }}</strong></h4>
                      <p class="mb-0"><small class="text-muted">Total order created</small></p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" md="6" class="d-none">
                <b-card class='mb-1'>
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <b-avatar 
                        size="36"
                        class="avatar-border-2 box-shadow-1"
                        variant="primary"
                        :src="require('@/assets/images/app-icons/ai/booking-48.png')" 
                      />
                    </div>
                    <div>
                      <h4><strong>{{ usageData.bookings }}</strong></h4>
                      <p class="mb-0"><small class="text-muted">Total bookings created</small></p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isEmpty } from '@/utilities'
import { mapActions } from 'vuex'
import ImageInput from '@/views/users/shared/ImageInput.vue'

export default {
  name: "UserDashboard",

  components: {
    ImageInput,
  },

  data: () => ({
    userAvatar: null,
    userData: {},
    usageData: {
      word_used: 0,
      ai_usages_templates: 0,
      image_used: 0,
      s2t_used: 0,
      t2s_used: 0,
      orders: 0,
      bookings: 0,
      word_balance: 0,
      image_balance: 0,
      s2t_balance: 0,
      t2s_balance: 0,
    }
  }),

  computed: {

  },

  methods: {
    ...mapActions('ai', [
      'getUsage'
    ]),
    inputFile(payload) {
      if (this.userData) this.userData.picture = payload
    },
  },

  created() {

  },

  mounted() {
    this.aiAvatar = require('@/assets/images/app-icons/ai/ai-48.png')
    let userData = localStorage.getItem('userData')
    if(!isEmpty(userData)){
      this.userData = JSON.parse(userData)
      this.userAvatar = `${process.env.VUE_APP_API_BASE_END_POINT}/${this.userData.picture}`
    }
    this.getUsage().then(r => {
      this.usageData = r
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.mobile-actions {
  a {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-actions {
    a {
      font-size: 12px;
      padding: 0.786rem 0.5rem;
      margin: 0 auto !important;
      margin-bottom: 10px !important;
      width: 280px;
      display: block;
    }
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
.fs-16{
  font-size: 3rem
}
</style>
